// servizi per l'interrogazione degli scarichi
import axios from "axios";
import { es } from "date-fns/locale";
import AuthService from "./auth.service";

// const SCARICHI_URL = "http://192.168.1.72:8080/api/v1/scarichi";
// const DEPOSITI_URL = "http://192.168.1.72:8080/api/v1/depositi"; 

const SCARICHI_URL = "http://5.249.157.198:8080/api/v1/scarichi";
const DEPOSITI_URL = "http://5.249.157.198:8080/api/v1/depositi";

export const getAllScarichi = async () => {
  var url = SCARICHI_URL;
  const currentUser = AuthService.getCurrentUser();

  try {
    const response = await axios({
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.token,
      },
    });
    console.log(
      "getAllScarichi\n" + url + "\nData:" + JSON.stringify(response.data)
    );
    return Promise.resolve(response.data);
  } catch (error) {
    console.error("getAllScarichi:\n" + url + "\n" + error);
    return Promise.reject(error);
  }
};

export const getItemScarico = async (id) => {
  var url = SCARICHI_URL + "/" + id;
  const currentUser = AuthService.getCurrentUser();
  try {
    const response = await axios({
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.token,
      },
    });
    console.log(
      "getItemScarico\n" + url + "\nData:" + JSON.stringify(response.data)
    );
    return Promise.resolve(response.data);
  } catch (error) {
    console.error("getItemScarico:\n" + url + "\n" + error);
    return Promise.reject(error);
  }
};

export const deleteScarico = async (id) => {
  var url = SCARICHI_URL + "/" + id;
  const currentUser = AuthService.getCurrentUser();

  let config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url: url,
    headers: { Authorization: "Bearer " + currentUser.token }
  };

  axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
}

export const modificaScarico = async (id, esportato) => {
  var url = SCARICHI_URL + "/" + id;
  const currentUser = AuthService.getCurrentUser();

  try {
    const response = await axios({
      params: { esportato: esportato },
      method: "put",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.token,
      },
    });
    console.log(
      "modificaScarico\n" + url + "\nData:" + JSON.stringify(response.data)
    );
    return Promise.resolve(response.data);
  } catch (error) {
    console.error("modificaScarico:\n" + url + "\n" + error);
    return Promise.reject(error);
  }
}

/** Depositi */

export const getAllDepositi = async () => {
  var url = DEPOSITI_URL;
  const currentUser = AuthService.getCurrentUser();

  try {
    const response = await axios({
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.token,
      },
    });
    console.log(
      "getAllDepositi\n" + url + "\nData:" + JSON.stringify(response.data)
    );
    return Promise.resolve(response.data);
  } catch (error) {
    console.error("getAllDepositi:\n" + url + "\n" + error);
    return Promise.reject(error);
  }
}